<template>
  <div class="balance">
    <div class="sub-wrap balance-index">
      <div class="container">
        <div class="header-wrap cf">
          <div class="header-left cf">
            <img src="@/assets/icons/guthaben.svg" />
            <h2>{{ $t("balance.payoutTitle") }}</h2>
          </div>
        </div>

        <div class="content-wrap">
          <header class="content-header">
            <h3>{{ $t("balance.payoutTitle2") }}</h3>
          </header>
          <div class="content-body">
            <form @submit="create_payout" class="form" method="post">
              <div class="form-wrap">
                <label for="amount">{{ $t("balance.payoutAmount") }}</label>
                <input v-model="payout.amount" type="text" class="form-input" />
              </div>

              <p style="margin: 0">
                <input type="submit" :value="$t('balance.payoutTitle2')" class="button button-ci button-round button-100" />
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "balance",
  data() {
    return {
      errors: [],
      user: {},
      payout: {},
    };
  },
  methods: {
    get_user() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/user", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.user = response.data.user;
        });
    },
    create_payout: function (e) {
      e.preventDefault();

      axios
        .post(
          process.env.VUE_APP_BASE_API + "/user/payouts",
          {
            user_uuid: this.user.uuid,
            amount: this.payout.amount,
          },
          { headers: { Authorization: this.$store.getters.getToken } }
        )
        .then((response) => {
          console.log(response);
          this.$notify({
            group: "notification",
            type: "success",
            text: this.$t("balance.payoutMessage"),
          });
          this.$router.push("/balance");
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
  },
  mounted() {
    this.get_user();
  },
};
</script>

<style></style>
